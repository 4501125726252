import React from 'react';
import './About.css';
import { motion } from 'framer-motion';
import { staggerContainer, fadeIn } from '../../utils/motion';
import { TypingText, TitleText } from '../CustomText';
import myfoto from '../../assets/images/my_foto.png';

const About = () => {
  return (
    <section id="about">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="about_container"
      >
        <TypingText title="|  Get To Know" />
        <TitleText title={<>About Me</>} />

        <div className="container about__container">
          <motion.div
            className="about__me"
            variants={fadeIn('right', 'tween', 0.2, 1)}
          >
            <div className="about__me-image">
              <img src={myfoto} alt="about image" />
            </div>
          </motion.div>
          <motion.div
            className="about__content"
            variants={fadeIn('left', 'tween', 0.2, 1)}
          >
            <p>
              During my time as a Frontend Developer, I give importance to
              communication and share information with my teammates. I am a
              person who takes active responsibility in the design processes of
              websites and their transfer to software. This allowed me to be
              self-motivated, solution-oriented, disciplined, and to look at
              problems from different perspectives in the face of difficulties.
            </p>
            <a href="#contact" className="btn btn-primary">
              Let's Talk
            </a>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default About;
