import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../../utils/motion';
import './Portfolio.css';

const PortfolioCard = ({ index, img, title, githubUrl, demoUrl, subtitle }) => {
  return (
    <motion.div
      className="portfolio__item"
      variants={fadeIn('up', 'spring', index * 0.6, 0.75)}
    >
      <div className="portfolio__item-image">
        <img src={img} alt="" />
      </div>
      <h3>{title}</h3>
      <p className="item-subtitle">{subtitle}</p>
      <div className="portfolio__item-cta">
        <a href={githubUrl} className="btn" target="_blank">
          Github
        </a>
        <a href={demoUrl} className="btn btn-primary" target="_blank">
          Live Demo
        </a>
      </div>
    </motion.div>
  );
};

export default PortfolioCard;
