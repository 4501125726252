import React from 'react';

import './Nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { TbUserSearch } from 'react-icons/tb';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { RiServiceLine } from 'react-icons/ri';
import { BiMessageDetail } from 'react-icons/bi';
import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../../utils/motion';
import { Link } from 'react-scroll';

const Nav = () => {
  return (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
    >
      <motion.nav variants={fadeIn('left', 'tween', 1, 1)}>
        <Link activeClass="active" smooth spy to="header">
          <a>
            <AiOutlineHome />
          </a>
        </Link>
        <Link activeClass="active" smooth spy to="portfolio">
          <a>
            <AiOutlineFundProjectionScreen />
          </a>
        </Link>

        <Link activeClass="active" smooth spy to="experience">
          <a>
            <RiServiceLine />
          </a>
        </Link>

        <Link activeClass="active" smooth spy to="about">
          <a>
            <TbUserSearch />
          </a>
        </Link>
        <Link activeClass="active" smooth spy to="contact">
          <a>
            <BiMessageDetail />
          </a>
        </Link>
      </motion.nav>
    </motion.div>
  );
};

export default Nav;
