import React from 'react';
import { motion } from 'framer-motion';
import './Portfolio.css';
import { projectInfo } from '../../utils/project';
import PortfolioCard from './PortfolioCard';
import { staggerContainer } from '../../utils/motion';
import { TypingText, TitleText } from '../CustomText';

const Portfolio = () => {
  return (
    <section id="portfolio">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="portfolio_container"
      >
        <TypingText title="|  My Recent Work" />
        <TitleText title={<>Portfolio</>} />

        <div className="container portfolio__container">
          {projectInfo.map((project, index) => (
            <PortfolioCard key={project.id} index={index} {...project} />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Portfolio;
