export const projectInfo = [
  {
    id: 'project-1',
    img: '/preview.jpg',
    title: 'E-Commerce Website',
    subtitle: 'Nextjs, Redux, CSS',
    githubUrl: 'https://github.com/abdullahkarahan/E-Commerce-Website',
    demoUrl: 'https://e-commerce-website-5ozwmqk4b-abdullahkarahan.vercel.app/',
  },
  {
    id: 'project-2',
    img: '/preview2.jpg',
    title: 'Travel Booking Website',
    subtitle: 'Nextjs, Typescript, Prisma, MongoDB, NextAuth, Tailwind',
    githubUrl: 'https://github.com/abdullahkarahan/Nextjs-Travel-App',
    demoUrl: 'https://travel-app-six-nu.vercel.app/',
  },
  {
    id: 'project-3',
    img: '/preview3.jpg',
    title: 'React Firebase Chat App',
    subtitle: 'React, Firebase, SCSS',
    githubUrl: 'https://github.com/abdullahkarahan/React-Firebase-Chat-App',
    demoUrl: 'https://my-react-firebase-chat-app.netlify.app/',
  },
];
