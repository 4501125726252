export const experience = [
  {
    id: 1,
    title: 'HTML',
  },
  {
    id: 2,
    title: 'CSS',
  },
  {
    id: 3,
    title: 'JavaScript',
  },
  {
    id: 4,
    title: 'React',
  },
  {
    id: 5,
    title: 'NextJS',
  },
  {
    id: 6,
    title: 'Tailwind',
  },
  {
    id: 7,
    title: 'Bootstrap',
  },
  {
    id: 8,
    title: 'Typescript',
  },
  {
    id: 9,
    title: 'Redux',
  },
];
