import React from 'react'
import './Experience.css'
import { IoShieldCheckmark } from 'react-icons/io5'

const ExperienceCard = ({ title }) => {
  return (
    <div className="experience__details">
      <IoShieldCheckmark className="experience__details-icon" />
      <div>
        <h4>{title}</h4>
      </div>
    </div>
  )
}

export default ExperienceCard
