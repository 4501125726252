import React from 'react';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../../utils/motion';

const HeaderSocials = () => {
  return (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
    >
      <motion.div
        className="header__socials"
        variants={fadeIn('right', 'tween', 1, 1)}
      >
        <a
          href="https://www.linkedin.com/in/abdullah-karahan-a4ab9143/"
          target="_blank"
          className="social-icons"
        >
          <BsLinkedin />
        </a>
        <a
          href="https://github.com/abdullahkarahan"
          target="_blank"
          className="social-icons"
        >
          <BsGithub />
        </a>
      </motion.div>
    </motion.div>
  );
};

export default HeaderSocials;
