import React from 'react'
import { motion } from 'framer-motion'

import { textContainer, textVariant2 } from '../utils/motion'

export const TypingText = ({ title }) => (
  <motion.h5 variants={textContainer} className="typing_text">
    {Array.from(title).map((letter, index) => (
      <motion.span variants={textVariant2} key={index}>
        {letter === ' ' ? '\u00A0' : letter}
      </motion.span>
    ))}
  </motion.h5>
)

export const TitleText = ({ title }) => (
  <motion.h2
    variants={textVariant2}
    initial="hidden"
    whileInView="show"
    className="fade_text"
  >
    {title}
  </motion.h2>
)
