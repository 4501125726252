import './Contact.css';
import { AiOutlineMail } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { staggerContainer, fadeIn } from '../../utils/motion';
import { TypingText, TitleText } from '../CustomText';
import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_eowtpji',
        'template_fz3bqdr',
        form.current,
        'bBl6XMf7iyi-1x50i'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  const notify = () =>
    toast.success('Your message has been sent successfully!', {
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
    });
  return (
    <section id="contact">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        className="main-container"
        viewport={{ once: false, amount: 0.25 }}
      >
        <div>
          <TypingText title="| Get In Touch" />
          <TitleText title={<>Contact Me</>} />
        </div>
        <div className="container contact__container">
          <motion.div
            className="contact_options"
            variants={fadeIn('up', 'spring', 0.5, 0.75)}
          >
            <article className="contact__option">
              <AiOutlineMail className="contact__option-icon" />
              <h4>Email</h4>
              <h5>abdullahkarahan1461@gmail.com</h5>
              <a href="mailto:abdullahkarahan1461@gmail.com" target="_blank">
                Send a message
              </a>
            </article>
          </motion.div>
          {/* END OF CONTACT OPTIONS */}
          <motion.form
            ref={form}
            onSubmit={sendEmail}
            variants={fadeIn('up', 'spring', 0.7, 0.75)}
          >
            <input
              type="text"
              name="name"
              placeholder="Your Full Name"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <textarea
              name="message"
              rows="7"
              placeholder="Your Message"
              required
            ></textarea>
            <button type="submit" onClick={notify} className="btn btn-primary">
              Send Message
            </button>
          </motion.form>
        </div>
      </motion.div>
    </section>
  );
};

export default Contact;
