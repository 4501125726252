import React from 'react';
import './Experience.css';
import { motion } from 'framer-motion';
import { experience } from '../../utils/experience';
import { IoShieldCheckmark } from 'react-icons/io5';
import ExperienceCard from './ExperienceCard';
import { staggerContainer, fadeIn } from '../../utils/motion';
import { TypingText, TitleText } from '../CustomText';

const Experience = () => {
  return (
    <section id="experience">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="experience_container"
      >
        <TypingText title="|  What Skills I Have" />
        <TitleText title={<>My Experience</>} />

        <motion.div
          className="container experience__container"
          variants={fadeIn('up', 'spring', 0.5, 0.75)}
        >
          <div className="experience__frontend">
            <h3>Frontend Development</h3>
            <div className="experience__content">
              {experience.map((item) => (
                <ExperienceCard key={item.id} {...item} />
              ))}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Experience;
