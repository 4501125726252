import React from 'react';
import { motion } from 'framer-motion';
import { footerVariants } from '../../utils/motion';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import './Footer.css';

const Footer = () => {
  return (
    <section id="footer">
      <motion.footer
        variants={footerVariants}
        initial="hidden"
        whileInView="show"
      >
        <ul className="permalinks">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#experience">Experience</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
        <div className="footer__socials">
          <a
            href="https://www.linkedin.com/in/abdullah-karahan-a4ab9143/"
            target="_blank"
            className="social-icons"
          >
            <BsLinkedin />
          </a>
          <a
            href="https://github.com/abdullahkarahan"
            target="_blank"
            className="social-icons"
          >
            <BsGithub />
          </a>
        </div>
      </motion.footer>
    </section>
  );
};

export default Footer;
