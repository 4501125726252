import React from 'react';
import HeaderSocials from './HeaderSocials';
import './Header.css';
import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../../utils/motion';
import logo from '../../assets/images/logo.png';

const Header = () => {
  return (
    <section id="header">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className="header"
      >
        <div className="container header__container">
          <div className="header-title">
            <img className="header-title" src={logo} alt="logo" />
          </div>

          <HeaderSocials />
        </div>
      </motion.div>
    </section>
  );
};

export default Header;
